
/*********** fonts *************/

@font-face {
    font-family: "Futura";   /*Can be any text*/
    src: local("Futura"),
      url("assets/fonts/Futura.ttf") format("ttf");
}

@font-face {
    font-family: "Futura-Bold";
    src: local("Futura-Bold"),
        url("assets/fonts/Futura-Bold.ttf") format("ttf");
}


.skiptranslate{
    display: none;
}

#goog-gt-{
    display: none;
}

body{
    
    padding: 0px 0px !important;
}

  